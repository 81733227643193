<template lang="pug">
.container
  page-title(title='Equipos')
    router-link.button.mr-1(v-if='selected' :to='{ name: adminDeviceDetailsRoute.name, params: { id: selected.id } }') Editar
    button.button.is-primary(@click='create' v-if='permissions.devicesCreate') Nuevo equipo
  .card
    .card-content
      custom-table(
        class='devices-grid'
        id='devices'
        :column-defs='columnDefs'
        :row-data='devices'
        row-data-key='id'
        @row-selected='deviceSelected'
        @link-clicked='deviceClicked'
        v-if='devices && devices.length'
      )
</template>

<script>
import CustomTable from '@/components/CustomTable'
import { clientsColumnDefs } from './constants'
import { adminDeviceDetails, clientDetail } from '@/router/routes'
import devicesService from '@/services/devices.service'
import { openCreateDeviceModal } from '@/components/devices/create-device.modal'

export default {
  components: { CustomTable },
  data () {
    return {
      adminDeviceDetailsRoute: adminDeviceDetails,
      columnDefs: clientsColumnDefs,
      selected: null,
      devices: []
    }
  },
  created () {
    this.getDevices()
  },
  computed: {
    permissions () {
      return this.$store.getters['auth/permissions']
    }
  },
  methods: {
    async getDevices () {
      const result = await devicesService.getAll()
      if (result) {
        this.devices = result
      }
    },
    create () {
      openCreateDeviceModal({
        modal: this.$buefy.modal,
        parent: this,
        props: {}
      })
    },
    deviceSelected (selected) {
      if (selected) {
        this.selected = selected.rowData
      }
    },
    deviceClicked (row) {
      switch (row.colData.field) {
        case 'id': this.$router.push({ name: adminDeviceDetails.name, params: { id: row.rowData.id } }); break
        case 'clientName': this.$router.push({ name: clientDetail.name, params: { id: row.rowData.clientId } }); break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.devices-grid {
  height: calc(100vh - 210px);
}
</style>
